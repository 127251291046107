import React from 'react'
import { graphql } from 'gatsby'
import 'twin.macro'

import Layout from '../../components/Layout'
import DesignSystem from '../../components/DesignSystem'
import ExpandableImage from '../../components/ExpandableImage'
import SEO from '../../components/SEO'

const About = ({ data }) => {
  return (
    <Layout>
      <SEO
        canonical="/about"
        image={data.img2.childImageSharp.gatsbyImageData.images.fallback.src}
        title="About Us | shaka.zone"
        type="article"
      />
      <div tw="max-w-full flex flex-col justify-center items-center">
        <article tw="flex flex-col items-center">
          <ExpandableImage
            image={data.img2.childImageSharp.gatsbyImageData}
            tw="mt-0"
            alt="Hawaii is beautiful"
          />
          <DesignSystem.H1>About Us</DesignSystem.H1>
          <DesignSystem.P>
            Nice to meet you! We are Bear and Lola, two Vancouver-based
            professionals building a community around a love for Hawaii. We have
            a Goldendoodle dog named Gilly who is very much a part of our
            family.
          </DesignSystem.P>
          <DesignSystem.P>
            We only discovered our love for the islands in 2019 when we took our
            first trip. Since then, we have been hooked.
          </DesignSystem.P>
          <DesignSystem.P>
            It is hard to explain our love for Hawaii. We ended up there for the
            first time on a whim - a brief work trip that was meant to last only
            four days. Every time we’ve returned we’ve fallen more in love with
            it. While we know that we have only ever been there in the
            “vacation” mindset, what we hold dear from Hawaii seems like the
            “everyday”. The warmness of the people. The approach to life, work,
            food and culture. The sea, the mountains, the lushness of it all.
          </DesignSystem.P>
          <DesignSystem.P>
            Hawaii has lit a fire in us that we carry with us. We find we see
            reminders of Hawaii on a daily basis, sometimes multiple times per
            day, almost to an uncanny level. We feel drawn to the islands and
            hope to one day make them our home.
          </DesignSystem.P>
          <DesignSystem.H2>About Our Blog</DesignSystem.H2>
          <DesignSystem.P>
            We started this blog as an outlet for our passion for Hawaii. We
            wanted to collect our memories of Hawaii and share them with the
            world in an intentional, organized way. We hope to create a
            community of “aloha lovers” - those called to Hawaii just as we are.
            The shaka zone is a place for those who want to engage in a
            knowledge exchange about all aspects of the islands. The shaka zone
            is a place to share in our passion for Hawai’i. We hope you will
            share your insights with us as well.
          </DesignSystem.P>
          <DesignSystem.H2>Hawaiian Lands</DesignSystem.H2>
          <DesignSystem.P>
            We would like to acknowledge that the topic of our blog is Hawai’i,
            which is a land with significant political, economic, traditional
            and societal histories that define the relationship between the land
            and its inhabitants. The original people of Hawai’i are the kānaka
            ʻōiwi (Native Hawaiian).
          </DesignSystem.P>
          <DesignSystem.P>
            Through our studies on Hawai’i we have learned that her majesty
            Queen Lili‘uokalani yielded the Hawaiian Kingdom and the Hawaiian
            territories under duress and protest to the United States to prevent
            violence toward the Hawaiian People. We have learned that the
            surrendering of Hawa’ii means that the reality for Native Hawaiians
            today is shaped by a history of racism and white supremacy. As
            uninvited guests on Hawai’i, we are committed to supporting Native
            Hawaiians in their efforts to preserve their livelihoods, culture,
            traditions and lands in a genuine way.
          </DesignSystem.P>
          <DesignSystem.P>
            We write about Hawai’i as settlers on the lands of the traditional,
            ancestral and unceded territories of the Sḵwx̱wú7mesh (Squamish),
            Səl̓ílwətaʔ/Selilwitulh (Tsleil-Waututh) and xʷməθkʷəy̓əm (Musqueam)
            Nations. These are lands currently occupied by “Canada”, in the area
            of “Vancouver”.
          </DesignSystem.P>
          <DesignSystem.P>
            We would like to thank the Sḵwx̱wú7mesh (Squamish),
            Səl̓ílwətaʔ/Selilwitulh (Tsleil-Waututh), xʷməθkʷəy̓əm (Musqueam) and
            Hawaiian Nations for allowing us to live, work, travel and play on
            their lands as uninvited guests. We acknowledge that as Indigenous
            peoples from separate traditional territories (Lola), and immigrants
            from other countries (Bear), our relationship with Indigenous
            peoples is complex. Nonetheless, as uninvited guests we are putting
            careful thought and reflection into the realities of colonialism and
            white supremacy. We are working everyday to better engage in
            reconciliation in a meaningful way.
          </DesignSystem.P>
        </article>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    img1: file(relativePath: { glob: "about/image1.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    img2: file(relativePath: { glob: "about/image2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

export default About
